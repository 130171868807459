<template>
  <div>
    <b-form-group
      label="Título"
      label-for="input-title"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-textarea
        id="input-title"
        v-model="$v.likert_scale.title.$model"
        :state="validateState('title')"
        aria-describedby="input-title-feedback"
        size="sm"
      ></b-form-textarea>
      <b-form-invalid-feedback id="input-title-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Descripción"
      label-for="input-description"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-textarea
        id="input-description"
        v-model="$v.likert_scale.description.$model"
        :state="validateState('description')"
        aria-describedby="input-description-feedback"
        size="sm"
      ></b-form-textarea>
      <b-form-invalid-feedback id="input-description-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <div class="row">
      <div
        v-if="show_delete_button && !isNaN(likert_scale.id)"
        class="col"
        style="text-align: left"
      >
        <b-button class="mr-1" size="sm" variant="danger" @click="askForDelete"
          >Eliminar</b-button
        >
      </div>
      <div class="col" style="text-align: right">
        <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { generateUniqueId, toast } from "@/utils/utils";

export default {
  name: "LikertScaleForm",
  mixins: [validationMixin],
  props: {
    LikertScale: {
      type: Object,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      likert_scale: {
        id: this.LikertScale ? this.LikertScale.id : generateUniqueId(),
        title: this.LikertScale ? this.LikertScale.title : "",
        description: this.LikertScale ? this.LikertScale.description : "",
      },
    };
  },
  validations() {
    return {
      likert_scale: {
        title: { required },
        description: {},
      },
    };
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.likert_scale[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.likert_scale.$touch();
      if (this.$v.likert_scale.$anyError) {
        return;
      }
      if (isNaN(this.likert_scale.id)) this.create();
      else this.update();
    },
    create() {
      this.$restful
        .Post("/cideu/likert-scale/", this.likert_scale)
        .then((response) => {
          this.likert_scale = response;
          this.$emit("created", response);
          toast("Escala creada.");
        });
    },
    update() {
      this.$restful
        .Put(
          `/cideu/likert-scale/${this.likert_scale.id}/`,
          this.likert_scale
        )
        .then((response) => {
          this.likert_scale = response;
          this.$emit("updated", response);
          toast("Escala actualizada.");
        });
    },
    askForDelete() {
      this.$swal({
        title: "¿Está seguro de que desea eliminar el Acta?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.delete();
        }
      });
    },
    delete() {
      this.$restful
        .Delete(`/cideu/likert-scale/${this.likert_scale.id}/`)
        .then(() => {
          this.$emit("deleted", this.likert_scale);
          toast("Escala eliminada.");
        });
    },
  },
};
</script>

<style>
</style>